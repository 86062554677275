import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { MyContextProvider } from "./components/context";
import Home from './pages/home';
import Header from './components/header';
import Footer from "./components/footer";
import NoPage from "./components/no-page";
import About from "./pages/about";
import Services from "./pages/services";
import ContactUs from "./pages/contact-us";
import HandymanServices from "./pages/handyman-services";
import Faqs from "./pages/faqs";
import Blogs from "./pages/blogs";
import ServiceDetailPage from "./components/service-detail-page";
import BlogDetailPage from "./components/blog-detail-page";
import ServerDashboard from './pages/server-dashboard';
import DashboardLogin from './pages/dashboard-login';

const MainLayout = ({ children }) => {
    const location = useLocation();
    const hideHeaderFooterPaths = ['/s_dashboard', '/dashboard_login'];

    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

function App() {
    return (
        <BrowserRouter>
            <MyContextProvider>
                <MainLayout>
                    <Routes>
                        <Route exact index element={<Home />} />
                        <Route exact path="about" element={<About />} />
                        <Route exact path="services" element={<Services />} />
                        <Route exact path="/services/:id" element={<ServiceDetailPage />} />
                        <Route exact path="handyman-services" element={<HandymanServices />} />
                        <Route exact path="faq" element={<Faqs />} />
                        <Route exact path="blogs" element={<Blogs />} />
                        <Route exact path="/blogs/:id" element={<BlogDetailPage />} />
                        <Route exact path="contact" element={<ContactUs />} />
                        {/* <Route exact path="dashboard_login" element={<DashboardLogin />} /> */}
                        {/* <Route exact path="s_dashboard" element={<ServerDashboard />} /> */}
                        <Route exact path="*" element={<NoPage />} />
                    </Routes>
                </MainLayout>
            </MyContextProvider>
        </BrowserRouter>
    );
}

export default App;
