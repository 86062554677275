import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMyContext } from '../components/context';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import UserIdCard from '../components/server-dashboard/user-id-card';

import "../sass/dashboard-login.scss";
import { Link } from 'react-router-dom';

function DashboardLogin() {
    const { setActiveItem } = useMyContext();
    const [activeTab, setActiveTab] = useState('support-chat');

    useEffect(() => {
        setActiveItem('dashboard_login');
    }, [setActiveItem]);

    // Function to handle tab change
    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <div className='dashboard-login-main'>
            <div className='content-holder-main'>
                <h4>Sign In</h4>
                <div className="input-holder">
                    <input className='form-control' type="text" placeholder='First Name' />
                </div>
                <div className="input-holder">
                    <input className='form-control' type="text" placeholder='First Name' />
                </div>
                <Link className='btn button-primary btn-c-rounded' to="/s_dashboard">login</Link>
            </div>
        </div>
    );
}

export default DashboardLogin;
